<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    dark
    temporary
  >
    <v-list>
      <v-list-item
        v-for="(section, i) in sections"
        :key="i"
        :to="section.to"
        :href="section.href"
        @click="onClick($event, section)"
      >
        <v-list-item-title v-text="section.text" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import {
    mapGetters,
    mapMutations,
  } from 'vuex'

  export default ({
    name: 'HomeDrawer',

    computed: {
      ...mapGetters(['sections']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },

    methods: {
      ...mapMutations(['setDrawer']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to === '/') {
          this.$vuetify.goTo(0)
          this.setDrawer(false)
          return
        }

        if (item.to || !item.href) return
        this.$vuetify.goTo(item.href)
        this.setDrawer(false)
      },
    },
  })
</script>

<style>

</style>
